/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import extend_89d5f86e from '/home/ergonode/pim/frontend/modules/@ergonode/attributes/src/config/extends.js';
import extend_8ca82d52 from '/home/ergonode/pim/frontend/modules/@ergonode/attribute-groups/src/config/extends.js';
import extend_78fc8ece from '/home/ergonode/pim/frontend/modules/@ergonode/categories/src/config/extends.js';
import extend_4acfefb8 from '/home/ergonode/pim/frontend/modules/@ergonode/category-trees/src/config/extends.js';
import extend_54eeae5e from '/home/ergonode/pim/frontend/modules/@ergonode/core/src/config/extends.js';
import extend_e7347eb8 from '/home/ergonode/pim/frontend/modules/@ergonode/dashboard/src/config/extends.js';
import extend_de7bec7c from '/home/ergonode/pim/frontend/modules/@ergonode/channels/src/config/extends.js';
import extend_532bdd18 from '/home/ergonode/pim/frontend/modules/@ergonode/media/src/config/extends.js';
import extend_9f4b6710 from '/home/ergonode/pim/frontend/modules/@ergonode/notifications/src/config/extends.js';
import extend_45f12db6 from '/home/ergonode/pim/frontend/modules/@ergonode/collections/src/config/extends.js';
import extend_09869056 from '/home/ergonode/pim/frontend/modules/@ergonode/products/src/config/extends.js';
import extend_638648aa from '/home/ergonode/pim/frontend/modules/@ergonode/product-templates/src/config/extends.js';
import extend_7075978d from '/home/ergonode/pim/frontend/modules/@ergonode/roles/src/config/extends.js';
import extend_52903c98 from '/home/ergonode/pim/frontend/modules/@ergonode/users/src/config/extends.js';
import extend_f3f56fd2 from '/home/ergonode/pim/frontend/modules/@ergonode/import/src/config/extends.js';
import extend_34a2990c from '/home/ergonode/pim/frontend/modules/@ergonode/batch-actions/src/config/extends.js';
import extend_ad43dae6 from '/home/ergonode/pim/frontend/modules/@ergonode/products-comments/src/config/extends.js';
import extend_36ad4e9c from '/home/ergonode/pim/frontend/modules/@ergonode/product-batch-actions/src/config/extends.js';
import extend_86a0df1e from '/home/ergonode/pim/frontend/modules/@ergonode/workflow/src/config/extends.js';

export default {
	extend_89d5f86e,
	extend_8ca82d52,
	extend_78fc8ece,
	extend_4acfefb8,
	extend_54eeae5e,
	extend_e7347eb8,
	extend_de7bec7c,
	extend_532bdd18,
	extend_9f4b6710,
	extend_45f12db6,
	extend_09869056,
	extend_638648aa,
	extend_7075978d,
	extend_52903c98,
	extend_f3f56fd2,
	extend_34a2990c,
	extend_ad43dae6,
	extend_36ad4e9c,
	extend_86a0df1e,
};
